<template>
  <div class="text-center mt-2" v-if="!dataLoaded">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </div>

  <div v-else>
    <v-row class="my-0">
      <v-col cols="12" class="pt-0">
        <v-card class="base-card pa-5">
          <div class="inputs">
            <div class="chairman">
              <v-autocomplete
                v-if="!chairman"
                v-model="chairman"
                class="pt-3 mt-0"
                label="Председатель комиссии"
                placeholder="Выберите значение из списка"
                :items="chairmansList"
                item-text="info"
                hide-selected
                return-object
                @change="setChairman"
              >
                <template slot="no-data">
                  <div class="px-4 py-3">Нет значений для выбора</div>
                </template>
              </v-autocomplete>

              <div class="d-flex" v-else>
                <v-text-field
                  disabled
                  :value="chairman.info"
                  class="pt-3 mt-0"
                  :class="{'disabled-color': !isCycleComplete}"
                  label="Председатель комиссии"
                />

                <v-btn
                  v-if="!isCycleComplete"
                  icon
                  class="mt-3"
                  color="accent"
                  title="Удалить председателя комиссии"
                  @click="removeChairman"
                >
                  <v-icon center>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="secretary">
              <v-autocomplete
                v-if="!secretary"
                v-model="secretary"
                class="pt-3 mt-0"
                label="Секретарь комиссии"
                placeholder="Выберите значение из списка"
                :items="secretariesList"
                item-text="info"
                hide-selected
                return-object
                @change="setSecretary"
              >
                <template slot="no-data">
                  <div class="px-4 py-3">Нет значений для выбора</div>
                </template>
              </v-autocomplete>

              <div class="d-flex" v-else>
                <v-text-field
                  disabled
                  :value="secretary.info"
                  class="pt-3 mt-0"
                  :class="{'disabled-color': !isCycleComplete}"
                  label="Секретарь комиссии"
                />

                <v-btn
                  v-if="!isCycleComplete"
                  icon
                  class="mt-3"
                  color="accent"
                  title="Удалить секретаря комиссии"
                  @click="removeSecretary"
                >
                  <v-icon center>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="member" v-for="(member, i) in members" :key="i">
              <v-autocomplete
                v-if="!member"
                v-model="members[i]"
                class="pt-3 mt-0"
                label="Член комиссии"
                placeholder="Выберите значение из списка"
                :items="membersListComputed"
                item-text="info"
                hide-selected
                :hide-details="i === members.length - 1"
                return-object
                @change="setMember(i)"
              >
                <template slot="no-data">
                  <div class="px-4 py-3">Нет значений для выбора</div>
                </template>
              </v-autocomplete>

              <div class="d-flex" v-else>
                <v-text-field
                  disabled
                  :value="member.info"
                  class="pt-3 mt-0"
                  :class="{'disabled-color': !isCycleComplete}"
                  label="Член комиссии"
                  :hide-details="i === members.length - 1"
                />

                <v-btn
                  v-if="!isCycleComplete"
                  icon
                  class="mt-3"
                  color="accent"
                  title="Удалить члена комиссии"
                  @click="removeMember(i)"
                >
                  <v-icon center>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>

            <v-btn
              v-if="members.length < membersList.length"
              small
              color="primary"
              class="white--text px-4"
              @click="members.push(null)"
            >
              <v-icon left small>mdi-plus</v-icon>Добавить
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { examApi, commissionOrderApi } from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'Commission',

  metaInfo: {
    title: 'Экзаменационная комиссия'
  },

  async created() {
    await this.getData()
    this.dataLoaded = true
  },

  data: () => ({
    dataLoaded: false,

    chairmansList: [],
    chairman: null,

    secretariesList: [],
    secretary: null,

    membersList: [],
    members: [null],
  }),

  computed: {
    ...mapGetters('cycle', ['cycleId', 'isCycleComplete']),

    membersListComputed() {
      const membersIds = this.members.map(el => el?.emplId)
      return this.membersList.map(el => membersIds.includes(el.emplId) ? { ...el, disabled: true } : el)
    }
  },

  methods: {
    async getData() {
      try {
        const [chairmansList, secretariesList, membersList, chairman, secretary, members] = await Promise.all([
          commissionOrderApi.getChairmans(this.cycleId),
          commissionOrderApi.getSecretaries(this.cycleId),
          commissionOrderApi.getMembers(this.cycleId),

          examApi.getChairman(this.cycleId),
          examApi.getSecretary(this.cycleId),
          examApi.getMembers(this.cycleId)
        ])

        if (chairmansList.length) this.chairmansList = chairmansList.map(el => this.setInfo(el))
        if (secretariesList.length) this.secretariesList = secretariesList.map(el => this.setInfo(el))
        if (membersList.length) this.membersList = membersList.map(el => this.setInfo(el))

        this.chairman = this.setInfo(chairman)
        this.secretary = this.setInfo(secretary)
        if (members.length) this.members = members.map(el => this.setInfo(el))
      } catch (e) {
        console.log(e.status)
      }
    },

    setInfo(el) {
      return el ? { ...el, info: `${el.fullName} (${el.emplWorkType}) ${el.emplTitleCode}` } : null
    },

    async setChairman() {
      try {
        const data = await examApi.setChairman(this.cycleId, this.chairman)
        this.chairman = this.setInfo(data)
      } catch (e) {
        this.chairman = null
        this.showErrorMessage(e, 'Ошибка сохранения председателя комиссии')
      }
    },

    async removeChairman() {
      try {
        await examApi.removeStaff(this.chairman.cycleStaffId)
        this.chairman = null
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления председателя комиссии')
      }
    },

    async setSecretary() {
      try {
        const data = await examApi.setSecretary(this.cycleId, this.secretary)
        this.secretary = this.setInfo(data)
      } catch (e) {
        this.secretary = null
        this.showErrorMessage(e, 'Ошибка сохранения секретаря комиссии')
      }
    },

    async removeSecretary() {
      try {
        await examApi.removeStaff(this.secretary.cycleStaffId)
        this.secretary = null
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления секретаря комиссии')
      }
    },

    async setMember(index) {
      try {
        const data = await examApi.setMember(this.cycleId, this.members[index])
        this.members[index] = this.setInfo(data)
      } catch (e) {
        this.members[index] = null
        this.showErrorMessage(e, 'Ошибка сохранения члена комиссии')
      }
    },

    async removeMember(index) {
      try {
        await examApi.removeStaff(this.members[index].cycleStaffId)
        this.members.splice(index, 1)
        !this.members.length && this.members.push(null)
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка удаления члена комиссии')
      }
    }
  }
}
</script>

<style scoped>
.base-card .inputs {
  max-width: 770px;
}

.base-card >>> .theme--light.v-input--is-disabled.disabled-color input[type=text] {
 color: var(--accent-color) !important;
}
</style>